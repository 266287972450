<template>
  <v-data-table
    :footer-props="{
      pageText: `{0}-{1} ${$t('tables.of')} {2}`,
      itemsPerPageText: $t('tables.requestsByPage'),
      itemsPerPageOptions: [5, 10, 20, 50, 100],
    }"
    :headers="affiliateHeaders"
    :no-data-text="$t('tables.noDataText')"
    :no-results-text="$t('tables.noDataText')"
    :items="receivedRequests"
    :search="searchString"
    class="elevation-1"
    :items-per-page="10"
    multi-sort>
    <template v-slot:top>
      <v-toolbar flat>
        <template>
          <v-toolbar-title>
            {{ $t('tables.receivedRequests') }}
          </v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>

          <v-text-field
            v-model="searchString"
            append-icon="mdi-magnify"
            hide-details
            :label="$t('tables.search')"
            single-line></v-text-field>

          <v-spacer />
        </template>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-tooltip left>
            <template v-slot:activator="{ on: tooltipOn }">
              <v-btn text v-on="{ ...on, ...tooltipOn }">
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('buttons.actions') }}
            </span>
          </v-tooltip>
        </template>
        <v-list>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item @click="openStatusDialog(item, 'accepted')">
                <v-icon class="mx-1" small v-bind="attrs">mdi-check</v-icon>
                <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                  {{ $t('buttons.accept') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t('tooltips.accept') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item @click="openStatusDialog(item, 'rejected')">
                <v-icon class="mx-1" small v-bind="attrs">mdi-close</v-icon>
                <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                  {{ $t('buttons.reject') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t('tooltips.reject') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item @click="openDeleteDialog(item)">
                <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                  {{ $t('buttons.delete') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t('tooltips.delete') }}</span>
          </v-tooltip>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import i18n from '@/i18n/i18n';
import { affiliateHeaders } from '@/mixins/data-table-headers';

export default {
  name: 'AffiliateReceivedRequests',
  props: ['receivedRequests'],
  components: {},
  data: () => ({
    searchString: '',
  }),
  created() {},
  computed: {
    affiliateHeaders() {
      return affiliateHeaders(i18n);
    },
  },
  methods: {
    openStatusDialog(item, status) {
      this.$emit('openStatusDialog', { ...item, status: status });
    },
    openDeleteDialog(item) {
      this.$emit('openDeleteDialog', { ...item, type: 'request' });
    },
  },
};
</script>
