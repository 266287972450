<template>
  <div>
    <v-data-table
      :footer-props="{
        pageText: `{0}-{1} ${$t('tables.of')} {2}`,
        itemsPerPageText: $t('tables.partnersByPage'),
        itemsPerPageOptions: [5, 10, 20, 50, 100],
      }"
      :headers="affiliateHeaders"
      :no-data-text="$t('tables.noDataText')"
      :no-results-text="$t('tables.noDataText')"
      :items="partners"
      :search="searchString"
      class="elevation-1"
      :items-per-page="10"
      multi-sort>
      <template v-slot:top>
        <v-toolbar flat>
          <template>
            <v-toolbar-title>
              {{ $t('tables.partners') }}
            </v-toolbar-title>

            <v-divider class="mx-4" inset vertical></v-divider>

            <v-text-field
              v-model="searchString"
              append-icon="mdi-magnify"
              hide-details
              :label="$t('tables.search')"
              single-line></v-text-field>

            <v-spacer />

            <v-tooltip bottom :disabled="!disabledForAffiliate">
              <template v-slot:activator="{ on, attrs }">
                <div style="display: block" v-bind="attrs" v-on="on">
                  <v-btn
                    class="mb-2 primary"
                    dark
                    @click="openEditDialog"
                    id="affiliate-page-step-2"
                    :disabled="disabledForAffiliate">
                    {{ $t('buttons.add') }}
                  </v-btn>
                </div>
              </template>
              <span v-if="disabledForAffiliate">{{ $t('tooltips.disabledForAffiliateUser') }}</span>
            </v-tooltip>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-tooltip left>
              <template v-slot:activator="{ on: tooltipOn }">
                <v-btn text v-on="{ ...on, ...tooltipOn }">
                  <v-icon> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('buttons.actions') }}
              </span>
            </v-tooltip>
          </template>
          <v-list>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item @click="openDeleteDialog(item)">
                  <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                  <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                    {{ $t('buttons.delete') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <span>{{ $t('tooltips.delete') }}</span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import { affiliateHeaders } from '@/mixins/data-table-headers';

export default {
  name: 'AffiliatePartners',
  props: ['partners'],
  components: {},
  data: () => ({
    searchString: '',
  }),
  created() {},
  computed: {
    affiliateHeaders() {
      return affiliateHeaders(i18n);
    },
  },
  methods: {
    openEditDialog() {
      this.$emit('openEditDialog');
    },
    openDeleteDialog(item) {
      this.$emit('openDeleteDialog', { ...item, type: 'partner' });
    },
  },
};
</script>
